@use "../../../styles/variables";

.filter {
  display: flex;
  // align-items: center;
  box-shadow: 0 2px 12px rgba(148, 148, 148, 0.15);
  // padding: 20px;

  &__menu {
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;
    // gap: 8px;
    overflow-x: scroll;
    width: 100%;
    // padding-left: 18px;

    &__item {
      cursor: pointer;
      color: white;
      font-size: 14px;
      white-space: pre;
      padding: 8px 20px;
      border-radius: 8px;
      transition: background 100ms ease, font-weight 100ms ease;
    }

    .selected {
      background: white;
      font-weight: bold;
      color: variables.$pink;
    }
  }
}

.library-concept-type-filter {
  display: flex;
  align-items: center;
  gap: 18px;
  flex-direction: column;

  &__logo {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .library-concept-type-filter {
    padding: 18px;
    gap: 12px;
    flex-direction: row;

    &__logo {
      width: 100px;
    }
  }

  .filter {
    &__menu {
      width: calc(100% - 430px); 
      padding-left: 0px;

      &__item {
        color: variables.$pink;
      }

      .selected {
        background: variables.$pink-bg;
      }
    }

  }
}
