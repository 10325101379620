.editable-input {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;

  textarea, .mirror-text {
    border: none;
    background: transparent;
    resize: none;
    overflow: hidden;

    &::placeholder {
      font-style: italic;
    }
  }

  img {
    opacity: 0;
    transition: opacity 100ms ease;
    cursor: pointer;
    color: #787E8D;
    height: 1em;
  }

  &:hover img {
    opacity: 1;
  }
}