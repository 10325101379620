.logo {
  display: flex;
  // align-items: center;
  // gap: 10px;
}

.logo-text {
  line-height: var(--size);
  font-size: var(--size);
  text-decoration: none;
  color: black;
}
