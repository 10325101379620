.library-grid-item {
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  overflow: hidden;
  font-size: 12px;
  background: white;
  border: 1px solid #e3e4e7;
  padding: 0;

  &__video-container {
    flex: 1 1 auto;
    min-height: 1px;
    height: 380px;
  }

  &__video-info {
    flex: 0 0;
    padding: 10px;
  }
}
