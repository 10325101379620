@use "../../styles/variables";

.header {
  height: variables.$header-height;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 12px rgba(148, 148, 148, 0.15);
  padding: 20px;

  &__menu {
    flex: 1 1 auto;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;
    gap: 8px;

    &__item {
      cursor: pointer;
      color: variables.$pink;
      font-size: 14px;

      a {
        text-decoration: none;
        color: inherit;
        padding: 8px 20px;
        border-radius: 8px;
        transition: background 100ms ease, font-weight 100ms ease, ;

        &.active {
          background: variables.$pink-bg;
          font-weight: bold;
        }
      }
    }
  }
}
