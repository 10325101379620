@use "../../styles/mixins";

.library {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // gap: 16px;

  &__content {
    flex: 1 1 auto;
    min-width: 1px;
    overflow-y: hidden;
    display: flex;
    background: #efefef;
    flex-direction: column;
    // border-radius: 12px;

    &__content_item {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.language-select-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 22px;
  div {
    margin-top: 0;
    width: 100%;
  }
}

.library-header {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding-bottom: 10px;
}

.dynamic-text {
  color: white;
}

@media (min-width: 768px) {
  .language-select-container {
    position: absolute;
    width: fit-content;
    padding-top: 0px;
    right: 0;
    top: 20px;
    div {
      width: 300px;
    }
  }

  .library-header {
    flex-direction: row;
    background-color: white;
  }

  .dynamic-text {
    color: black;
  }
}
