.app-loader {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-wrapper {
    position: relative;
    z-index: 3;

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      width: 1px;
      height: 1px;
      animation: animation1 5s infinite;
      animation-direction: alternate;
      opacity: 0.3;
      background: #ff44b4;
      box-shadow: 0 0 300px 100px #ff44b4;
      transform: translate(-50%, -50%);
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      width: 1px;
      height: 1px;
      animation: animation2 3s infinite;
      animation-direction: alternate;
      opacity: 0.3;
      background: #31d2dc;
      box-shadow: 0 0 250px 100px #31d2dc;
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes animation1 {
  from {
    top: -35%;
    left: -35%;
  }
  to {
    top: 100%;
    left: 100%;
  }
}

@keyframes animation2 {
  from {
    top: 100%;
    left: 100%;
  }
  to {
    top: -75%;
    left: -75%;
  }
}
