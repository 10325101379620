@import url('https://fonts.googleapis.com/css?family=Nunito');

.maverick, .app-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__content {
    flex: 1 1 auto;
    min-height: 1px;
    overflow-y: hidden;
  }
}

.app-loader {
  justify-content: center;
  align-items: center;
}

.app-toast {
  padding-left: 3em;

  h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  i {
    position: absolute;
    transform: translate(-200%, 40%);
    font-size: 16px
  }

  &--success i {
    color: #65EAC2;
  }
}

