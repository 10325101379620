.share-buttons {
  display: flex;

  &__button {
    cursor: pointer;
    color: #5D4FB0;
    font-size: 16px;
    transform: scale(0.8);
    transition: transform 100ms ease;

    &:hover {
      transform: scale(0.9);
    }
  }
}