.user-menu {
    min-width: 200px;
    padding: .5em 1em;
    font-size: 12px;

    &__connected-as {
        line-height: 28px;
    }

    i {
        margin-right: .5em;
    }
}