@for $i from 8 through 40 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}