$avatar-height: 24px;

.multi-avatar {
  position: relative;
  display: flex;
  align-items: center;
  height: $avatar-height;
  gap: 3px;

  img {
    height: $avatar-height;
    width: $avatar-height;
    border-radius: 5px;
  }
}