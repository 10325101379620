@import "./styles/highcharts";
@import "./styles/utilities";

* {
  box-sizing: border-box !important;
}

a[id="atlwdg-trigger"] {
  background: white !important;
  font-family: "Biennale", sans-serif;
  box-shadow: none !important;
  border: none !important;
  border-top: 1px solid #dadada !important;
  border-left: 1px solid #dadada !important;
  border-radius: 5px 0 0 0 !important;
}


input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  &:focus {
    outline: none;
  }
}

button {
  cursor: pointer;
}